import { DocsDownArrowIcon } from "@gadgetinc/widgets/src/icons/DocsDownArrowIcon";
import { DocsRightArrowIcon } from "@gadgetinc/widgets/src/icons/DocsRightArrowIcon";
import { OpenInNewTabIcon } from "@gadgetinc/widgets/src/icons/OpenInNewTabIcon";
import { expandBorderColors, marginVertical, paddingVertical } from "@gadgetinc/widgets/src/utils";
import { useStyletron } from "baseui";
import { omit } from "lodash";
import type { ReactNode } from "react";
import React, { useCallback, useContext, useEffect, useRef } from "react";
import type { StyleObject } from "styletron-react";
import { useHover } from "web/src/lib/hooks";
import { useLocation } from "wouter";
import { isInternalDocsLink } from "../../markdown-components/DocLink";
import { DocsNavContext } from "../DocsNavContext";

export const useOnDocsNavLinkClick = () => {
  const [_, setLocation] = useLocation();

  return useCallback(
    (href: string) => {
      return (event: React.MouseEvent<HTMLElement>) => {
        if (isInternalDocsLink(href)) {
          if (!event.ctrlKey && !event.metaKey) {
            event.preventDefault();
            setLocation(href);
          }
        }
      };
    },
    [setLocation]
  );
};

export const DocsNavLink = (props: React.ComponentProps<typeof NavLinkContents>) => {
  const linkRef = useRef<HTMLDivElement>(null);
  const [location] = useLocation();
  useEffect(() => {
    if (props.anchorProps.href == location) {
      if (linkRef.current) {
        linkRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  }, [props.anchorProps, location]);

  return (
    <NavItemWrapper ref={linkRef} depth={props.depth}>
      <NavLinkContents {...props} />
    </NavItemWrapper>
  );
};

export const DocsNavLinkHeader = (props: JSX.IntrinsicElements["span"] & { $style?: StyleObject }) => {
  const [css, $theme] = useStyletron();
  return (
    <NavItemWrapper>
      <span
        className={css({
          ...$theme.typography.LabelMedium,
          color: $theme.colors.contentTertiary,
          overflow: "hidden",
          paddingLeft: $theme.sizing.scale400,
          position: "relative",
          width: "100%",
          marginTop: $theme.sizing.scale600,
          marginBottom: $theme.sizing.scale100,
          ...props.$style,
        })}
        {...omit(props, ["$style"])}
      />
    </NavItemWrapper>
  );
};

export const DocsNavLinkSubHeader = (props: JSX.IntrinsicElements["span"] & { $style?: StyleObject }) => {
  const [_, $theme] = useStyletron();
  return (
    <DocsNavLinkHeader
      style={{ ...$theme.typography.LabelXSmall, marginTop: $theme.sizing.scale100, ...props.$style }}
      {...omit(props, ["$style"])}
    />
  );
};

const NavItemWrapper = React.forwardRef<HTMLDivElement, JSX.IntrinsicElements["div"] & { depth?: number }>((props, ref) => {
  const [css, $theme] = useStyletron();
  const depth = props.depth || 1;

  return (
    <div
      ref={ref}
      className={css({
        display: "flex",
        alignItems: "center",
        position: "relative",
        marginTop: $theme.sizing.scale300,
        scrollMarginTop: $theme.sizing.scale900,
        scrollMarginBottom: $theme.sizing.scale900,
        paddingLeft: `${(depth - 1) * 12}px`,
        minHeight: "25px",
        userSelect: "none",
      })}
      {...props}
    >
      {props.children}
    </div>
  );
});

const ToggleExpandedIcon = (props: { itemId: string }) => {
  const [css, $theme] = useStyletron();
  const { expandNavItem, collapseNavItem, getIsExpanded } = useContext(DocsNavContext);
  const expanded = !!getIsExpanded(props.itemId);
  const endEnhancerStyles = css({
    flexShrink: 0,
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100%",
    width: "25px",
  });

  const iconContainer = css({
    display: "flex",
    alignItems: "center",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignSelf: "center",
    cursor: "pointer",
  });

  return (
    <div
      className={endEnhancerStyles}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        if (expanded) {
          collapseNavItem(props.itemId);
        } else {
          expandNavItem(props.itemId);
        }
      }}
    >
      <div className={iconContainer}>
        {expanded ? (
          <DocsDownArrowIcon $style={{ width: $theme.sizing.scale500, height: $theme.sizing.scale500 }} color={$theme.colors.primary500} />
        ) : (
          <DocsRightArrowIcon $style={{ width: $theme.sizing.scale550, height: $theme.sizing.scale550 }} color={$theme.colors.primary500} />
        )}
      </div>
    </div>
  );
};

export const NavLinkContents = (props: {
  $style?: StyleObject;
  anchorProps: JSX.IntrinsicElements["a"];
  children: React.ReactNode;
  icon?: ReactNode;
  itemId?: string;
  depth?: number;
  selected?: boolean;
  hasChildren?: boolean;
  childIsSelected?: boolean;
  endEnhancer?: ReactNode;
  selectedEndEnhancer?: ReactNode;
  alwaysShowExternalEndEnhancer?: boolean;
}) => {
  const [css, $theme] = useStyletron();
  const [isHovered, hoverProps] = useHover();

  const flexIconStyles = css({ flexShrink: 0, height: $theme.sizing.scale600 });
  const endEnhancerStyles = css({
    flexShrink: 0,
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100%",
  });

  const hasEndEnhancer = (props.selected && props.selectedEndEnhancer) || props.endEnhancer;
  const linkTextStyle = css({
    marginRight: hasEndEnhancer ? undefined : $theme.sizing.scale600,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  });

  const commonStyles = css({
    ...$theme.typography.LabelXSmall,
    fontSize: props.depth && props.depth > 1 ? $theme.sizing.scale500 : $theme.sizing.scale550,
    ...paddingVertical($theme.sizing.scale100),
    ...marginVertical(props.depth && props.depth > 1 ? 0 : $theme.sizing.scale100),
    lineHeight: "120%",
    minHeight: "25px",
    alignItems: "center",
    backgroundColor: props.selected || props.childIsSelected ? $theme.colors.primary200 : "transparent",
    borderRadius: $theme.borders.radius200,
    color: props.selected || props.childIsSelected ? $theme.colors.black : $theme.colors.primary600,
    cursor: props.selected ? "default" : "pointer",
    display: "flex",
    fontWeight: props.selected || props.childIsSelected ? 500 : 400,
    gap: $theme.sizing.scale400,
    overflow: "hidden",
    paddingLeft: $theme.sizing.scale400,
    position: "relative",
    textDecoration: "none",
    width: "100%",

    ":hover": {
      color: $theme.colors.black,
    },
    ":focus": {
      outline: "none",
      ...expandBorderColors($theme.colors.borderSelected),
    },
    ...props.$style,
  });

  return (
    <a {...hoverProps} {...props.anchorProps} className={commonStyles}>
      {props.icon && <div className={flexIconStyles}>{props.icon}</div>}

      <div className={linkTextStyle}>{props.children}</div>

      {props.selected && props.selectedEndEnhancer ? (
        <div className={endEnhancerStyles}>{props.selectedEndEnhancer}</div>
      ) : props.endEnhancer ? (
        <div className={endEnhancerStyles}>{props.endEnhancer}</div>
      ) : props.hasChildren && props.itemId ? (
        <ToggleExpandedIcon itemId={props.itemId} />
      ) : null}

      {props.anchorProps?.target === "_blank" && (
        <OpenInNewTabIcon
          className={
            endEnhancerStyles +
            " " +
            css({ color: $theme.colors.contentSecondary, opacity: props.alwaysShowExternalEndEnhancer ? 1 : isHovered ? 1 : 0 })
          }
        />
      )}
    </a>
  );
};
