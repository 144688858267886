import { useStyletron } from "baseui";
import React from "react";

export const languageLabel = (language: string) => {
  const key = language.toLowerCase();

  switch (key) {
    case "javascript": {
      return "JavaScript";
    }
    case "typescript": {
      return "TypeScript";
    }
    case "react": {
      return "React";
    }
    case "graphql": {
      return "GraphQL";
    }
    case "shell":
    case "shell-session": {
      return "Shell";
    }
    default: {
      return language;
    }
  }
};

export const LanguageIndicator = (props: { children: React.ReactNode; color: string; onClick?: () => void }) => {
  const [css, $theme] = useStyletron();

  return (
    <div
      className={css({
        ...$theme.typography.MonoLabelXSmall,
        color: props.color,
        cursor: "pointer",
      })}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};
