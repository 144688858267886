import { StyledSvg } from "baseui/icon";
import React from "react";

export const DataDocIcon = (props: React.ComponentProps<typeof StyledSvg>) => {
  return (
    <StyledSvg viewBox="0 0" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_68_812)">
        <path
          d="M2.66582 7.7657C2.81622 7.6161 2.90022 7.4129 2.90022 7.2001V4.0001C2.90022 3.78792 2.98451 3.58444 3.13454 3.43441C3.28457 3.28438 3.48805 3.2001 3.70022 3.2001C3.9124 3.2001 4.11588 3.11581 4.26591 2.96578C4.41594 2.81575 4.50022 2.61227 4.50022 2.4001C4.50022 2.18792 4.41594 1.98444 4.26591 1.83441C4.11588 1.68438 3.9124 1.6001 3.70022 1.6001C2.37702 1.6001 1.30022 2.6769 1.30022 4.0001V6.8689L0.734622 7.4345C0.660265 7.50873 0.601275 7.59689 0.561027 7.69394C0.520778 7.791 0.500061 7.89503 0.500061 8.0001C0.500061 8.10516 0.520778 8.2092 0.561027 8.30625C0.601275 8.4033 0.660265 8.49147 0.734622 8.5657L1.30022 9.1313V12.0001C1.30022 13.3233 2.37702 14.4001 3.70022 14.4001C3.9124 14.4001 4.11588 14.3158 4.26591 14.1658C4.41594 14.0158 4.50022 13.8123 4.50022 13.6001C4.50022 13.3879 4.41594 13.1844 4.26591 13.0344C4.11588 12.8844 3.9124 12.8001 3.70022 12.8001C3.48805 12.8001 3.28457 12.7158 3.13454 12.5658C2.98451 12.4158 2.90022 12.2123 2.90022 12.0001V8.8001C2.90037 8.69502 2.87974 8.59096 2.83951 8.49389C2.79929 8.39682 2.74026 8.30866 2.66582 8.2345L2.43142 8.0001L2.66582 7.7657Z"
          fill="#5C5F62"
        />
        <path
          d="M16.4394 7.69394C16.3992 7.59689 16.3402 7.50873 16.2658 7.4345L15.7002 6.8689V4.0001C15.7002 2.6769 14.6234 1.6001 13.3002 1.6001C13.088 1.6001 12.8846 1.68438 12.7345 1.83441C12.5845 1.98444 12.5002 2.18792 12.5002 2.4001C12.5002 2.61227 12.5845 2.81575 12.7345 2.96578C12.8846 3.11581 13.088 3.2001 13.3002 3.2001C13.5124 3.2001 13.7159 3.28438 13.8659 3.43441C14.0159 3.58444 14.1002 3.78792 14.1002 4.0001V7.2001C14.1001 7.30517 14.1207 7.40924 14.1609 7.50631C14.2012 7.60338 14.2602 7.69153 14.3346 7.7657L14.569 8.0001L14.3346 8.2345C14.1842 8.3841 14.1002 8.5873 14.1002 8.8001V12.0001C14.1002 12.2123 14.0159 12.4158 13.8659 12.5658C13.7159 12.7158 13.5124 12.8001 13.3002 12.8001C13.088 12.8001 12.8846 12.8844 12.7345 13.0344C12.5845 13.1844 12.5002 13.3879 12.5002 13.6001C12.5002 13.8123 12.5845 14.0158 12.7345 14.1658C12.8846 14.3158 13.088 14.4001 13.3002 14.4001C14.6234 14.4001 15.7002 13.3233 15.7002 12.0001V9.1313L16.2658 8.5657C16.3402 8.49147 16.3992 8.4033 16.4394 8.30625C16.4797 8.2092 16.5004 8.10516 16.5004 8.0001C16.5004 7.89503 16.4797 7.791 16.4394 7.69394Z"
          fill="#5C5F62"
        />
        <circle cx="8.60007" cy="8.10019" r="0.9" fill="#5C5F62" />
        <circle cx="11.4" cy="8.10019" r="0.9" fill="#5C5F62" />
        <circle cx="5.80002" cy="8.10019" r="0.9" fill="#5C5F62" />
      </g>
      <defs>
        <clipPath id="clip0_68_812">
          <rect width="16" height="16" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </StyledSvg>
  );
};
