import { StyledSvg } from "baseui/icon";
import React from "react";

export const RoutesDocIcon = (props: React.ComponentProps<typeof StyledSvg>) => {
  return (
    <StyledSvg viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M5.99194 1.08936L3.99194 12.0894" stroke="#545454" />
      <path d="M9.99194 1.08936L7.99194 12.0894" stroke="#545454" />
      <circle cx="1.5" cy="4.5" r="1" fill="#545454" />
      <circle cx="1.5" cy="8.5" r="1" fill="#545454" />
    </StyledSvg>
  );
};
