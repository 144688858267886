import { useStyletron } from "baseui";
import { useNavigationDetails } from "fastify-renderer/client/react";
import { find, omit } from "lodash";
import React, { useContext, useMemo } from "react";
import { toSlug } from "state-trees/src/stringUtils";
import { DocsContext } from "../DocsContext";
import { DocsNavContext } from "../DocsNavContext";
import { DocsNavLink, DocsNavLinkHeader, DocsNavLinkSubHeader, useOnDocsNavLinkClick } from "./DocsNavLink";
import type { GadgetItemProps, StatefulNavItemProps } from "./navigation-types";
import { GadgetOnlyHeader } from "./navigation-types";
import { useDocsLocation } from "./useDocsLocation";

export const StatefulNavSidebar = (props: { subNav: Array<GadgetItemProps & { expanded?: boolean; hidden?: boolean }>; depth: number }) => {
  return !props.subNav.length ? null : (
    <ul>
      {props.subNav.map((item, i: number) => {
        return <StatefulNavItem key={`${item.itemId}-${i}`} {...item} depth={props.depth} hidden={false} />;
      })}
    </ul>
  );
};

const StatefulNavItem = (props: StatefulNavItemProps) => {
  const [css] = useStyletron();
  const [currentLocation] = useDocsLocation();
  const [_, navigationLocation] = useNavigationDetails();
  const { topLevelItems } = useContext(DocsContext);
  const { getIsExpanded } = useContext(DocsNavContext);

  const location = navigationLocation ?? currentLocation;

  const pageIsSelected =
    location.replace(/#.*$/, "") === props.itemId && (!find(topLevelItems, { itemId: props.itemId }) || props.depth === 1);
  const headingIsSelected = location === props.itemId && (!find(topLevelItems, { itemId: props.itemId }) || props.depth === 1);

  const expanded = !!getIsExpanded(props.itemId);

  const mappedSubNav: StatefulNavItemProps[] | null = useMemo(() => {
    return !props.subNav || !props.subNav.length
      ? null
      : props.subNav.map((item: any): StatefulNavItemProps => {
          const mappedSubNavItem = {
            ...item,
            hidden: !expanded,
            depth: props.depth + 1,
          };
          return mappedSubNavItem;
        });
  }, [props.subNav, props.depth, expanded]);

  const onDocsNavLinkClick = useOnDocsNavLinkClick();

  return (
    <li
      className={css({
        display: props.hidden ? "none" : "flex",
        flexDirection: "column",
        position: "relative",
      })}
    >
      {props.onlyHeader ? (
        props.onlyHeader === GadgetOnlyHeader.Header ? (
          <DocsNavLinkHeader>{props.title}</DocsNavLinkHeader>
        ) : (
          <DocsNavLinkSubHeader>{props.title}</DocsNavLinkSubHeader>
        )
      ) : (
        <DocsNavLink
          {...omit(props, ["hidden", "subNav", "title"])}
          itemId={props.itemId}
          selected={headingIsSelected}
          childIsSelected={pageIsSelected}
          hasChildren={!!mappedSubNav}
          anchorProps={{
            href: props.itemId,
            onClick: onDocsNavLinkClick(props.itemId),
          }}
        >
          <div
            data-testid={`sidebarLink-${toSlug(props.itemId)}`}
            className={css({
              whiteSpace: "break-spaces",
              position: "relative",
              zIndex: 1,
              overflowX: "visible",
            })}
          >
            {props.title}
          </div>
        </DocsNavLink>
      )}
      {!mappedSubNav ? null : (
        <ul>
          {mappedSubNav.map((item, i: number) => {
            return <StatefulNavItem key={`${item.itemId}-${i}`} {...item} />;
          })}
        </ul>
      )}
    </li>
  );
};
