import { PlainButton, expandBorderRadii, expandMargin } from "@gadgetinc/widgets";
import darkTheme, { gqlLightTheme } from "@gadgetinc/widgets/src/code-block-themes";
import { ChevronIcon } from "@gadgetinc/widgets/src/icons/ChevronIcon";
import { styled, useStyletron } from "baseui";
import { AnimateSharedLayout, motion } from "framer-motion";
import type { Language, PrismTheme } from "prism-react-renderer";
import Highlight, { defaultProps } from "prism-react-renderer";
import lightTheme from "prism-react-renderer/themes/nightOwlLight";
import React, { useCallback, useState } from "react";
import { prettifyCodeExample } from "state-trees/src/prettifyCodeExample";
import { useHover } from "../../lib/hooks";
import "./AdditionalPrismLanguages";
import { handleCopy } from "./CodeBlock";
import "./GellyPrism";

const Line = styled("div", ({ $theme }) => ({
  display: "table-row",
  color: $theme.colors.primary600,
}));

const LineNo = styled("span", {
  display: "table-cell",
  textAlign: "right",
  paddingRight: "1em",
  userSelect: "none",
  opacity: "0.5",
});

const LineContent = styled("span", {
  display: "table-cell",
});

const Pre = styled("pre", ({ $theme }) => ({
  ...$theme.typography.MonoParagraphSmall,
  paddingLeft: $theme.sizing.scale400,
  paddingRight: $theme.sizing.scale400,
  ...expandBorderRadii($theme.borders.radius200),
  cursor: "pointer",
  WebkitTextSizeAdjust: "100px !important",
  MozTextSizeAdjust: "100px !important",
}));

export const useCodeHighlightTheme = (kind?: "light" | "dark" | "gqlLight") => {
  const [_css, $theme] = useStyletron();
  let theme: PrismTheme;
  let labelColor;

  switch (kind) {
    case "gqlLight":
      theme = gqlLightTheme;
      labelColor = $theme.colors.contentPrimary;
      break;
    case "light":
      theme = lightTheme;
      labelColor = $theme.colors.contentPrimary;
      break;
    case "dark":
    case undefined:
      theme = darkTheme;
      labelColor = $theme.colors.primary300;
      break;
  }
  return { theme, labelColor };
};

export const ExpandableCodeHighlight = (props: {
  kind?: "light" | "dark" | "gqlLight";
  children: string;
  language: string;
  copyOnCodeClick?: boolean;
  linesShownBeforeSeeMoreOption?: number; // The number of lines to show before toggleable truncation with a see-more button
  codePrintWidth?: number;
}) => {
  const { children: code, language } = props;
  const [css, $theme] = useStyletron();
  const [isHovered, hoverProps] = useHover();

  const formattedCode = prettifyCodeExample(code, language, props.codePrintWidth ?? 30).replaceAll(`{" "}`, "");

  const lineCount = formattedCode.split("\n").length;
  const linesShownBeforeSeeMoreOption = props.linesShownBeforeSeeMoreOption ?? lineCount;
  const useShowMoreButton = lineCount > linesShownBeforeSeeMoreOption;
  const [isShowingFullCodeSnippet, setIsShowingFullCodeSnippet] = useState(!useShowMoreButton);
  const collapsed = props.linesShownBeforeSeeMoreOption && !isShowingFullCodeSnippet;

  return (
    <AnimateSharedLayout>
      <motion.div
        key={language}
        className={css({
          overflowX: "auto",
          overflowY: "hidden",
          position: "relative",
          paddingBottom: useShowMoreButton ? 0 : $theme.sizing.scale300,
          "::-webkit-scrollbar": { height: $theme.sizing.scale200 },
          "::-webkit-scrollbar-track": { backgroundColor: "transparent" },
          "::-webkit-scrollbar-thumb": {
            backgroundColor: isHovered ? "rgba(255, 255, 255, 0.6)" : "transparent",
            borderRadius: $theme.sizing.scale900,
          },
        })}
        initial={false}
        animate={{
          height: collapsed ? props.linesShownBeforeSeeMoreOption! * 20 : "auto",
        }}
        {...hoverProps}
      >
        <CodeHighlight
          language={language === "react" ? "tsx" : language}
          kind="dark"
          forceLineNumbers={false} // Hide line numbers to get more width
          copyOnCodeClick
          appendBlankLine={useShowMoreButton}
          numberOflinesShown={isShowingFullCodeSnippet ? lineCount : linesShownBeforeSeeMoreOption}
          fadeOutLastLines={!isShowingFullCodeSnippet}
        >
          {formattedCode}
        </CodeHighlight>
        {/* render a spacer div when we are expanded that occupies the space for the See More button */}
        {useShowMoreButton && <div className={css({ height: "26px" })} />}
      </motion.div>
      {useShowMoreButton && (
        <div
          className={css({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "absolute",
            transform: "translateY(-40px)",
            width: "100%",
          })}
        >
          {useShowMoreButton && (
            <ShowFullCodeSnippetToggleButton
              isShowingFullCodeSnippet={isShowingFullCodeSnippet}
              setIsShowingFullCodeSnippet={setIsShowingFullCodeSnippet}
            />
          )}
        </div>
      )}
    </AnimateSharedLayout>
  );
};

export const CodeHighlight = (props: {
  kind?: "light" | "dark" | "gqlLight";
  children: string;
  className?: string;
  language: string;
  forceLineNumbers?: boolean;
  copyOnCodeClick?: boolean;
  numberOflinesShown?: number;
  fadeOutLastLines?: boolean;
  appendBlankLine?: boolean;
}) => {
  const code = props.children.trim();
  const [css, $theme] = useStyletron();
  const language = props.language == "javascript" || props.language == "react" ? "jsx" : props.language;
  const { theme, labelColor } = useCodeHighlightTheme(props.kind);

  const copyCode = useCallback(() => void handleCopy(code), [code, language]);
  const lineCount = code.split("\n").length;

  return (
    <Highlight {...defaultProps} code={code} language={language as Language} theme={theme}>
      {({ className, tokens, getLineProps, getTokenProps }) => {
        const enableLineNumbers = props.forceLineNumbers ?? (!["shell", "shell-session"].includes(language) && tokens.length > 5);
        return (
          <div className={css({ backgroundImage: props.fadeOutLastLines ? "linear-gradient(transparent, black)" : undefined })}>
            <Pre
              className={className}
              onClick={props.copyOnCodeClick ? copyCode : undefined}
              style={{
                color: theme.plain.color,
                WebkitTextSizeAdjust: "100px !important",
                MozTextSizeAdjust: "100px !important",
              }}
            >
              {tokens.slice(0, props.numberOflinesShown ?? lineCount).map((line, i) => (
                <Line key={i} {...getLineProps({ line, key: i })}>
                  {enableLineNumbers && <LineNo style={{ color: labelColor }}>{i + 1}</LineNo>}
                  <LineContent
                    $style={{
                      paddingRight: "16px",
                      opacity: props.fadeOutLastLines ? getLineOpacity(i, props.numberOflinesShown ?? lineCount) : 1,
                    }}
                  >
                    {line.map((token, key) => (
                      <span key={key} {...getTokenProps({ token, key })} />
                    ))}
                  </LineContent>
                </Line>
              ))}
            </Pre>
          </div>
        );
      }}
    </Highlight>
  );
};

function ShowFullCodeSnippetToggleButton(props: {
  isShowingFullCodeSnippet: boolean;
  setIsShowingFullCodeSnippet: (isShowingFullCodeSnippet: boolean) => void;
}) {
  const [css, $theme] = useStyletron();
  const { isShowingFullCodeSnippet, setIsShowingFullCodeSnippet } = props;
  return (
    <PlainButton
      onClick={() => setIsShowingFullCodeSnippet(!isShowingFullCodeSnippet)}
      disabled={false}
      $style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "center",
        gap: $theme.sizing.scale300,
        ...expandMargin($theme.sizing.scale400),
        backgroundColor: "transparent",
        color: $theme.colors.primary300,
        boxShadow: "none",
        ":hover": { color: $theme.colors.primary100, backgroundColor: "transparent" },
        textDecoration: "none",
        // Prevents the automatic outlining from pressing shift to horizontal scroll
        ":focus-visible": { outline: "0" },
      }}
    >
      <ChevronIcon
        className={css({ transform: `rotate(${isShowingFullCodeSnippet ? "270" : "90"}deg)`, transition: "transform 0.25s ease-in-out" })}
      />
      {`See ${isShowingFullCodeSnippet ? "less" : "more"}`}
    </PlainButton>
  );
}

function getLineOpacity(currentLineNumber: number, totalLineCount: number) {
  // Incrementally make final 4 lines less opaque
  if (currentLineNumber === totalLineCount - 3) return 0.9;
  if (currentLineNumber === totalLineCount - 2) return 0.7;
  if (currentLineNumber === totalLineCount - 1) return 0.5;
  if (currentLineNumber === totalLineCount) return 0.3;

  return 1;
}
