import { StyledSvg } from "baseui/icon";
import React from "react";

export const SourceControlDocsIcon = (props: React.ComponentProps<typeof StyledSvg>) => {
  return (
    <StyledSvg viewBox="0 0" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.79996 1.6C3.62807 1.6 2.66663 2.56144 2.66663 3.73333C2.66663 4.72016 3.35154 5.54965 4.26663 5.78958V10.1333V10.2104C3.35154 10.4504 2.66663 11.2798 2.66663 12.2667C2.66663 13.4386 3.62807 14.4 4.79996 14.4C5.97185 14.4 6.93329 13.4386 6.93329 12.2667C6.93329 11.2798 6.24838 10.4504 5.33329 10.2104V10.1333C5.33329 9.90667 5.3812 9.81355 5.46871 9.71771C5.55622 9.62187 5.71931 9.52272 5.96871 9.43958C6.46752 9.27331 7.25902 9.19745 8.08746 9.05937C8.9159 8.9213 9.80399 8.71654 10.527 8.15416C11.1623 7.66009 11.6125 6.87449 11.7125 5.79687C12.6383 5.5645 13.3333 4.72787 13.3333 3.73333C13.3333 2.56144 12.3719 1.6 11.2 1.6C10.0281 1.6 9.06663 2.56144 9.06663 3.73333C9.06663 4.71141 9.73984 5.53401 10.6427 5.78229C10.5646 6.56629 10.2779 6.99749 9.87288 7.3125C9.39593 7.68345 8.68402 7.8787 7.91246 8.00729C7.1409 8.13588 6.3324 8.19335 5.63121 8.42708C5.53005 8.4608 5.43071 8.49993 5.33329 8.54375V5.78958C6.24838 5.54965 6.93329 4.72016 6.93329 3.73333C6.93329 2.56144 5.97185 1.6 4.79996 1.6ZM4.79996 2.66667C5.39538 2.66667 5.86663 3.13791 5.86663 3.73333C5.86663 4.31228 5.41989 4.7697 4.84788 4.79479C4.82918 4.79311 4.8104 4.79241 4.79163 4.79271C4.77806 4.79288 4.76451 4.79358 4.751 4.79479C4.1795 4.76918 3.73329 4.31193 3.73329 3.73333C3.73329 3.13791 4.20454 2.66667 4.79996 2.66667ZM11.2 2.66667C11.7954 2.66667 12.2666 3.13791 12.2666 3.73333C12.2666 4.31228 11.8199 4.7697 11.2479 4.79479C11.2292 4.79311 11.2104 4.79241 11.1916 4.79271C11.1781 4.79288 11.1645 4.79358 11.151 4.79479C10.5795 4.76918 10.1333 4.31193 10.1333 3.73333C10.1333 3.13791 10.6045 2.66667 11.2 2.66667ZM4.75204 11.2052C4.78427 11.2081 4.81669 11.2081 4.84892 11.2052C5.42042 11.2308 5.86663 11.6881 5.86663 12.2667C5.86663 12.8621 5.39538 13.3333 4.79996 13.3333C4.20454 13.3333 3.73329 12.8621 3.73329 12.2667C3.73329 11.6877 4.18003 11.2303 4.75204 11.2052Z"
        fill="black"
      />
    </StyledSvg>
  );
};
