import type { EnvironmentSlug, EnvironmentType, LegacyEnvironmentName } from "state-trees/src/Environment";
import type { FieldType } from "state-trees/src/type-system/FieldType";
import type { FrameworkVersionConstraint } from "state-trees/src/versioning/FrameworkVersions";

export interface DocsVersion {
  id: string;
  prefix: string;
  rootURL: string;
  dir: string;
  frameworkRange: string;
  label: string;
}

export const DocsVersions: DocsVersion[] = [
  {
    id: "actions-as-code",
    prefix: "/v0",
    rootURL: "/v0/",
    dir: "docs/pages/guides/2-actions-as-code",
    frameworkRange: "<=0.3.1",
    label: "v0",
  },
  {
    id: "multi-env",
    prefix: "",
    rootURL: "/",
    dir: "docs/pages/guides/3-multi-env",
    frameworkRange: ">=1.0.0",
    label: "v1",
  },
];

export const MultiEnvironmentDocsVersion = DocsVersions[1];
export const ActionsAsCodeDocsVersion = DocsVersions[0];

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const DefaultDocsVersion = DocsVersions.find((version) => version.prefix == "")!;
export interface FieldUsageExampleBlob {
  exampleGraphQLMutation: string;
  exampleGraphQLVariables: Record<string, any>;
  exampleImperativeInvocation: string;
  exampleReactHook: string;
}

export interface MetaNode {
  name: string;
  apiIdentifier: string;
  namespace?: string[];
}

export interface MetaField extends MetaNode {
  fieldType: FieldType;
  configuration?: {
    relatedModelKey: string;
  };
  examples?: {
    linkExistingChild: FieldUsageExampleBlob;
    linkNewChild: FieldUsageExampleBlob;
    linkToExistingParent: FieldUsageExampleBlob;
    createNestedInParent: FieldUsageExampleBlob;
  };
}

export interface MetaAction extends MetaNode {
  operatesWithRecordIdentity: boolean;
  acceptsInput: boolean;
  isDeleteAction: boolean;
  isCreateOrUpdateAction: boolean;
  hasAmbiguousIdentifier: boolean;
  bulkInvokedByIDOnly: boolean;
  examples: {
    exampleGraphQLMutation: string;
    exampleGraphQLVariables: Record<string, any>;
    exampleImperativeInvocation: string;
    exampleReactHook: string;
    exampleJSInputs: Record<string, any>;
    exampleBulkImperativeInvocation?: string;
    exampleBulkGraphQLMutation?: string;
    exampleBulkReactHook?: string;
    bulkOutputGraphQLTypeSDL?: string;
  };
}

export interface MetaModel extends MetaNode {
  namespace: string[];
  key: string;
  actions: MetaAction[];
  fields: MetaField[];
}

export interface FullMetaBlob {
  id: string;
  name: string;
  slug: string;
  isExample: boolean;
  environmentsMetaData: FullEnvironmentMetaBlob[];
  environment: FullEnvironmentMetaBlob; // Currently selected environment for this app
  environmentID: string;
  environmentName: LegacyEnvironmentName;
  models: MetaModel[];
  hasGlobalActions: boolean;
  hasShopifyConnection: boolean;
  hasSplitEnvironments: boolean;
  multiEnvironmentEnabled: boolean;
  hasLegacyEffectCards: boolean;
  canonicalRenderURL: string;
  developmentRenderURL: string;
  productionRenderURL: string;
  currentEnvironmentRenderURL: string;
  editURL: string;
  graphQLEndpoint: string;
  developmentGraphQLEndpoint: string;
  productionGraphQLEndpoint: string;
  jsPackageIdentifier: string;
  shopifyConnectionApiVersion?: string;
  hasTemplateConfiguration: boolean;
  hasTemplateSetup: boolean;
}

export interface FullEnvironmentMetaBlob {
  slug: EnvironmentSlug;
  type: EnvironmentType;
  frameworkVersion: FrameworkVersionConstraint;
}

export interface ShortMetaBlob {
  hasLegacyEffectCards: boolean;
  name: string;
  slug: string;
}
