import { DISCORD_INVITE_URL } from "@gadgetinc/widgets";
import { GraphQLAPIPlaygroundIcon } from "@gadgetinc/widgets/src/icons/GraphQLAPIPlaygroundIcon";
import { APIPlaygroundIcon } from "@gadgetinc/widgets/src/icons/APIPlaygroundIcon";
import { useStyletron } from "baseui";
import React, { useContext } from "react";
import { ListItem, OrderedList, UnorderedList } from "web/src/components/markdown/ListItems";
import { TBody, TD, THead, Table } from "web/src/components/markdown/Table";
import { Blockquote, InlineCode, Paragraph } from "web/src/components/markdown/Typography";
import { DocsContext } from "../chrome/DocsContext";
import { CodeBlock } from "./CodeBlock";
import { DocLink } from "./DocLink";
import { H1, H2, H3, H4, H5, H6 } from "./Headings";
import { TableHeadCell } from "./TableHeadCell";
import { TableRow } from "./TableRow";

export { Anchor } from "web/src/components/edit/shared/Anchor";
export { Image } from "web/src/components/markdown/Image";
export { ListItem, OrderedList, UnorderedList } from "web/src/components/markdown/ListItems";
export { Mermaid } from "web/src/components/markdown/Mermaid";
export { TD, TH, TR, Table } from "web/src/components/markdown/Table";
export { Blockquote, InlineCode, Paragraph } from "web/src/components/markdown/Typography";
export { Video } from "web/src/components/markdown/Video";
export { Flag } from "web/src/lib/flags";
export { AsciinemaPlayer } from "./AsciinemaPlayer";
export { Callout } from "./Callout";
export { CodeBlock } from "./CodeBlock";
export { DocLink } from "./DocLink";
export { DocsTabs } from "./DocsTabs";
export { EmbeddedVideo } from "./EmbeddedVideo";
export { ForkButton, ForkButtonCallout } from "./ForkButtonCallout";
export { H1, H2, H3, H4, H5, H6 } from "./Headings";
export { OnClickLink } from "./OnClickLink";
export { Pill } from "./Pill";
export { APIReferenceRelativeLink, ExampleFallbackAPIReferenceRelativeLink } from "./RelativeLinks";
export { SearchBoost } from "./SearchBoost";
export { TabbedCodeExample } from "./TabbedCodeExample";
export { TableHeadCell as TableHead } from "./TableHeadCell";
export { TableRow } from "./TableRow";

export const MarkdownComponents = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
  li: ListItem,
  p: Paragraph,
  ol: OrderedList,
  ul: UnorderedList,
  inlineCode: InlineCode,
  blockquote: Blockquote,
  a: DocLink,
  code: CodeBlock,
  table: Table,
  thead: THead,
  tbody: TBody,
  tr: TableRow,
  td: TD,
  th: TableHeadCell,
};

export const DiscordLink = () => <DocLink href={DISCORD_INVITE_URL}>Discord</DocLink>;

export const APIPlaygroundLink = () => {
  const [css, $theme] = useStyletron();
  const context = useContext(DocsContext);

  return (
    <DocLink href={`${context.currentApp.canonicalRenderURL}api/playground/javascript?environment=${context.currentApp.environment.slug}`}>
      <span className={css({ verticalAlign: "middle" })}>{<APIPlaygroundIcon />}</span>&nbsp;
      {context.currentApp.slug} API playground
    </DocLink>
  );
};

export const GraphQLPlaygroundLink = () => {
  const [css, $theme] = useStyletron();
  const context = useContext(DocsContext);

  return (
    <DocLink href={`${context.currentApp.canonicalRenderURL}api/playground/graphql?environment=${context.currentApp.environment.slug}`}>
      <span className={css({ verticalAlign: "middle" })}>{<GraphQLAPIPlaygroundIcon />}</span>&nbsp;
      {context.currentApp.slug} GraphQL playground
    </DocLink>
  );
};
