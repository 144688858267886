import type { Item, NavItemProps } from "baseui/side-navigation";

export enum GadgetOnlyHeader {
  Header = "Header",
  SubHeader = "SubHeader",
}

export type GadgetItemProps = Omit<Item, "itemId" | "subNav"> & {
  itemId: string;
  onlyHeader?: GadgetOnlyHeader;
  icon?: any;
  subNav?: GadgetItemProps[] | PageLevelHeading[] | NavItem[];
  testIsActive?: (location: string) => boolean;
};

export type GadgetNavProps = Omit<NavItemProps, "item"> & { item: GadgetItemProps };

export interface HeadingItem extends GadgetItemProps {
  nodeName: "H1" | "H2" | "H3" | "H4" | "H5" | "H6";
  subNav?: HeadingItem[];
}

export interface NavItem {
  itemId: string;
  title: string;
  nodeName: "H1" | "H2" | "H3" | "H4" | "H5" | "H6";
}

export interface PageLevelHeading extends GadgetItemProps {
  nodeName?: "H1";
  subNav?: PageLevelHeading[] | HeadingItem[];
}

export type StatefulNavItemProps = GadgetItemProps & {
  itemId: string;
  depth: number;
  hidden?: boolean;
  subNav?: any;
};

export const navItemIsActive = (item: GadgetItemProps, location: string) => {
  return item.testIsActive ? item.testIsActive(location) : location.startsWith(item.itemId);
};
