import { StyledSvg } from "baseui/icon";
import React from "react";

export const ToolsDocIcon = (props: React.ComponentProps<typeof StyledSvg>) => {
  return (
    <StyledSvg viewBox="0 0" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.8 3.29654C12.8 5.11716 11.3241 6.59307 9.50345 6.59307C9.07829 6.59307 8.67193 6.51259 8.29877 6.36602L2.79721 11.8676C2.28226 12.3825 1.44736 12.3825 0.932411 11.8676C0.417456 11.3526 0.417456 10.5177 0.932411 10.0027L6.43395 4.50123C6.28738 4.12807 6.20689 3.7217 6.20689 3.29654C6.20689 1.47591 7.68281 0 9.50345 0C9.71013 0 9.91237 0.0190202 10.1085 0.0554058C10.3528 0.100716 10.4196 0.397534 10.2418 0.57104L8.24138 2.52331L10.2193 4.50123L12.2299 2.53901C12.4051 2.36799 12.6963 2.43692 12.742 2.67748C12.7801 2.87799 12.8 3.08493 12.8 3.29654Z"
        fill="#545454"
      />
    </StyledSvg>
  );
};
