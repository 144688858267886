import { useStyletron } from "baseui";
import { StyledSvg } from "baseui/icon";
import React from "react";

export const SparkleIcon = (props: React.ComponentProps<typeof StyledSvg>) => {
  const [_, $theme] = useStyletron();

  return (
    <StyledSvg viewBox="0 0 16 16" {...props}>
      <path
        d="M15.7779 8.51653L12.181 7.31212L11.0249 3.56489C10.982 3.42688 10.8591 3.33354 10.7199 3.33354C10.5807 3.33354 10.4578 3.42688 10.4152 3.56489L9.25907 7.31212L5.66219 8.51653C5.52939 8.56087 5.43979 8.68888 5.43979 8.83389C5.43979 8.97889 5.52939 9.1069 5.66187 9.15124L9.25875 10.3556L10.4149 14.1029C10.4578 14.2409 10.5807 14.3342 10.7199 14.3342C10.8591 14.3342 10.982 14.2409 11.0245 14.1029L12.1807 10.3556L15.7776 9.15124C15.9104 9.1069 16 8.97889 16 8.83389C16 8.68888 15.9104 8.56087 15.7779 8.51653ZM0.538571 4.3166L3.02694 5.18066L3.8564 7.77282C3.89992 7.90883 4.02184 8.0005 4.15976 8.0005C4.29769 8.0005 4.41993 7.90883 4.46313 7.77249L5.29259 5.18032L7.78096 4.31627C7.91184 4.27127 7.99984 4.14393 7.99984 4.00025C7.99984 3.85657 7.91184 3.72923 7.78096 3.6839L5.29259 2.81984L4.46313 0.227681C4.41993 0.0916724 4.29769 0 4.15976 0C4.02184 0 3.8996 0.0916724 3.8564 0.228014L3.02694 2.81984L0.538571 3.6839C0.407688 3.72957 0.319686 3.85657 0.319686 4.00025C0.319686 4.14393 0.407688 4.27127 0.538571 4.3166ZM5.54091 12.6841L3.77256 12.0701L3.1831 10.2286C3.1399 10.0926 3.01766 10.0006 2.87974 10.0006C2.74181 10.0006 2.61957 10.0923 2.57637 10.2286L1.98692 12.0701L0.218884 12.6841C0.0883218 12.7295 0 12.8568 0 13.0005C0 13.1442 0.0880018 13.2715 0.218884 13.3168L1.98724 13.9309L2.57637 15.7723C2.61989 15.908 2.74181 16 2.87974 16C3.01766 16 3.1399 15.9083 3.1831 15.772L3.77256 13.9305L5.54091 13.3165C5.67179 13.2712 5.7598 13.1438 5.7598 13.0005C5.7598 12.8571 5.67179 12.7295 5.54091 12.6841Z"
        fill={props.color ?? (props.$disabled ? $theme.colors.contentStateDisabled : "currentcolor")}
      />
    </StyledSvg>
  );
};
