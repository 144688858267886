import { StyledSvg } from "baseui/icon";
import React from "react";

export const FrontendsDocIcon = (props: React.ComponentProps<typeof StyledSvg>) => {
  return (
    <StyledSvg viewBox="0 0" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.5 9.23706C9.19036 9.23706 9.75 8.67742 9.75 7.98706C9.75 7.2967 9.19036 6.73706 8.5 6.73706C7.80964 6.73706 7.25 7.2967 7.25 7.98706C7.25 8.67742 7.80964 9.23706 8.5 9.23706Z"
        fill="#545454"
      />
      <path
        d="M8.5 10.8529C6.99757 10.8892 5.4989 10.6867 4.06 10.2529C3.39531 10.0422 2.77537 9.71026 2.2315 9.27389C2.02397 9.12482 1.85114 8.93261 1.72487 8.71046C1.59861 8.48831 1.52189 8.24148 1.5 7.98689C1.5 7.16039 2.408 6.35039 3.929 5.82039C5.40637 5.35076 6.94996 5.12316 8.5 5.14639C10.0294 5.12502 11.5523 5.34851 13.011 5.80839C13.6511 6.01247 14.2501 6.32835 14.78 6.74139C14.984 6.88351 15.1542 7.06875 15.2786 7.28399C15.4031 7.49923 15.4786 7.73919 15.5 7.98689C15.5 8.84589 14.485 9.71639 12.85 10.2574C11.439 10.6771 9.97192 10.8779 8.5 10.8529ZM8.5 5.74439C7.01706 5.72457 5.54041 5.94094 4.1255 6.38539C2.7255 6.87389 2.098 7.54189 2.098 7.98539C2.098 8.44939 2.7725 9.17889 4.2535 9.68539C5.63004 10.0983 7.06331 10.2905 8.5 10.2549C9.9082 10.2801 11.312 10.0895 12.6625 9.68989C14.2 9.18039 14.9 8.44989 14.9 7.98689C14.8748 7.828 14.8169 7.67607 14.7299 7.5407C14.643 7.40533 14.529 7.28945 14.395 7.20039C13.9209 6.83396 13.3858 6.55401 12.8145 6.37339C11.419 5.93527 9.96249 5.72292 8.5 5.74439Z"
        fill="#545454"
      />
      <path
        d="M5.65999 14.2215C5.42674 14.2268 5.19624 14.1702 4.99199 14.0575C4.27599 13.6445 4.02799 12.4535 4.32849 10.871C4.65981 9.35653 5.23389 7.90563 6.02849 6.57449C6.77385 5.23904 7.72808 4.03144 8.85499 2.99749C9.35157 2.54484 9.92442 2.18376 10.547 1.93099C10.7722 1.82546 11.0178 1.77075 11.2665 1.77075C11.5152 1.77075 11.7608 1.82546 11.986 1.93099C12.7305 2.35999 12.977 3.67399 12.6295 5.36049C12.2879 6.79277 11.7288 8.16421 10.9715 9.42699C10.2524 10.7467 9.32817 11.9437 8.23349 12.9735C7.71888 13.4439 7.12166 13.815 6.47199 14.068C6.2119 14.1645 5.93737 14.2164 5.65999 14.2215ZM6.54649 6.87149C5.78854 8.14615 5.23832 9.53334 4.91649 10.981C4.63999 12.4385 4.90549 13.315 5.29149 13.538C5.69149 13.7695 6.66249 13.55 7.84149 12.52C8.8865 11.5335 9.7686 10.3876 10.455 9.12499C11.1805 7.91775 11.7168 6.60656 12.0455 5.23699C12.3725 3.64949 12.09 2.67749 11.689 2.44649C11.5387 2.3889 11.3781 2.36312 11.2173 2.37078C11.0566 2.37844 10.8991 2.41937 10.755 2.49099C10.2008 2.71966 9.69135 3.0443 9.24999 3.44999C8.17332 4.44038 7.26183 5.59645 6.54999 6.87449L6.54649 6.87149Z"
        fill="#545454"
      />
      <path
        d="M11.3385 14.228C10.661 14.228 9.80052 13.818 8.90452 13.0475C7.75787 12.0041 6.78751 10.7822 6.03102 9.42899C5.24639 8.11617 4.67686 6.68626 4.34402 5.19349C4.19968 4.53673 4.17293 3.85958 4.26502 3.19349C4.28631 2.94639 4.36155 2.70698 4.48544 2.49212C4.60932 2.27727 4.77884 2.09221 4.98202 1.94999C5.72552 1.51949 6.98702 1.96199 8.27452 3.10499C9.34482 4.11608 10.2537 5.28534 10.9695 6.57199C11.7537 7.85416 12.3292 9.25276 12.6745 10.7155C12.8251 11.3963 12.8483 12.0992 12.743 12.7885C12.718 13.0427 12.6382 13.2885 12.5093 13.509C12.3804 13.7295 12.2053 13.9195 11.996 14.066C11.7951 14.1774 11.5682 14.2333 11.3385 14.228ZM6.54852 9.12799C7.27424 10.4212 8.20117 11.5907 9.29452 12.5925C10.419 13.56 11.311 13.768 11.6945 13.5425C12.0945 13.31 12.3895 12.361 12.0855 10.8255C11.7525 9.42896 11.2005 8.09394 10.45 6.86999C9.76694 5.63855 8.89939 4.51892 7.87752 3.54999C6.66552 2.47399 5.68252 2.23349 5.28202 2.46549C5.15719 2.56693 5.05476 2.69315 4.98117 2.83618C4.90759 2.97921 4.86447 3.13595 4.85452 3.29649C4.77502 3.89053 4.80092 4.49394 4.93102 5.07899C5.25117 6.50656 5.79739 7.8738 6.54902 9.12899L6.54852 9.12799Z"
        fill="#545454"
      />
    </StyledSvg>
  );
};
