import { StyledSvg } from "baseui/icon";
import React from "react";

export const ModelsDocIcon = (props: React.ComponentProps<typeof StyledSvg>) => {
  return (
    <StyledSvg viewBox="0 0" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.16664 2.1333C2.57731 2.1333 2.09998 2.61063 2.09998 3.19997V4.26663C2.09998 4.85597 2.57731 5.3333 3.16664 5.3333H4.23331V12.2666C4.23332 12.4081 4.28952 12.5437 4.38953 12.6437C4.48955 12.7438 4.6252 12.8 4.76664 12.8H9.56664C9.56664 13.3893 10.044 13.8666 10.6333 13.8666H13.8333C14.4226 13.8666 14.9 13.3893 14.9 12.8V11.7333C14.9 11.144 14.4226 10.6666 13.8333 10.6666H10.6333C10.044 10.6666 9.56664 11.144 9.56664 11.7333H5.29998V8.5333H9.56664C9.56664 9.12263 10.044 9.59997 10.6333 9.59997H13.8333C14.4226 9.59997 14.9 9.12263 14.9 8.5333V7.46663C14.9 6.8773 14.4226 6.39997 13.8333 6.39997H10.6333C10.044 6.39997 9.56664 6.8773 9.56664 7.46663H5.29998V5.3333H6.36664C6.95598 5.3333 7.43331 4.85597 7.43331 4.26663V3.19997C7.43331 2.61063 6.95598 2.1333 6.36664 2.1333H3.16664Z"
        fill="#545454"
      />
    </StyledSvg>
  );
};
