import { StyledSvg } from "baseui/icon";
import React from "react";

export const EnvironmentsDocIcon = (props: React.ComponentProps<typeof StyledSvg>) => (
  <StyledSvg viewBox="0 0 16 16" {...props}>
    <path
      d="M7.83333 8.39003L2.5 5.39003V11C2.5 11.2407 2.63 11.4627 2.84 11.5814L7.83333 14.39V8.39003ZM8.5 7.23536L13.8333 4.23536L8.82667 1.41936C8.62333 1.30536 8.376 1.30536 8.17333 1.41936L3.16667 4.23536L8.5 7.23536ZM9.16667 8.39003V14.39L14.16 11.5814C14.37 11.4627 14.5 11.2407 14.5 11V5.39003L9.16667 8.39003Z"
      fill="#545454"
    />
  </StyledSvg>
);
