import { Card, CardSection, Column, Input, NewPill, Row, expandBorderRadii, paddingUtil } from "@gadgetinc/widgets";
import { SearchIcon } from "@gadgetinc/widgets/src/icons/SearchIcon";
import { useStyletron } from "baseui";
import { Button, KIND } from "baseui/button";
import { ChevronDown } from "baseui/icon";
import type { ItemT } from "baseui/menu";
import { NestedMenus, StatefulMenu } from "baseui/menu";
import { StatefulPopover } from "baseui/popover";
import { PLACEMENT } from "baseui/toast";
import { ParagraphSmall, ParagraphXSmall } from "baseui/typography";
import { find } from "lodash";
import React, { type ReactNode, useMemo, useState } from "react";
import type { EnvironmentSlug } from "state-trees/src/Environment";
import { ProductionEnvironmentName, ProductionEnvironmentSlug } from "state-trees/src/Environment";
import type { StyleObject } from "styletron-react";
import { Divider } from "./Divider";
import { CheckmarkIcon } from "@gadgetinc/widgets/src/icons/CheckmarkIcon";
import { EnvironmentNamePill } from "../components/EnvironmentNameTag";
import { filter } from "fuzzaldrin-plus";

const HighlightLatest = ({ text }: { text: string | undefined }) => {
  if (!text) return null;

  const keyword = "Latest";
  const index = text.indexOf(keyword);

  if (index !== -1) {
    return (
      <span>
        {text.substring(0, index)}
        <NewPill>{keyword}</NewPill>
        {text.substring(index + keyword.length)}
      </span>
    );
  }

  return <span>{text}</span>;
};

export const DocsSelector = (props: {
  options: ItemT[];
  label?: ReactNode;
  value: string;
  onChange: (item: ItemT) => void;
  disabled?: boolean;
  buttonStyle?: StyleObject;
}) => {
  const [css, $theme] = useStyletron();

  const options = useMemo(() => {
    return props.options.map((option) => ({
      ...option,
      label: (
        <span
          className={css({
            fontWeight: option.id === props.value ? 600 : undefined,
          })}
        >
          <HighlightLatest text={option.label} />
        </span>
      ),
    }));
  }, [css, props.options, props.value]);

  const selected = find(options, { id: props.value });

  return (
    <StatefulPopover
      placement={PLACEMENT.bottomRight}
      focusLock
      autoFocus
      overrides={{
        Body: {
          style: {
            marginLeft: $theme.sizing.scale300, // hacky way to fix the popover position
          },
        },
      }}
      content={({ close }) => (
        <NestedMenus>
          <StatefulMenu
            items={options}
            overrides={{
              List: {
                style: {
                  maxHeight: "300px",
                  overflowY: "auto",
                  width: "262px",
                },
              },
            }}
            onItemSelect={({ item }) => {
              props.onChange(item);
              close();
            }}
          />
        </NestedMenus>
      )}
    >
      <Button
        size="compact"
        disabled={props.disabled}
        kind={KIND.tertiary}
        endEnhancer={() => <ChevronDown size={20} />}
        overrides={{
          Root: {
            props: {
              "data-testid": "docs-version-selector",
            },
            style: {
              width: "100%",
              paddingLeft: $theme.sizing.scale300,
              paddingRight: "2px",
              ...expandBorderRadii($theme.borders.radius200),
              justifyContent: "space-between",
              ...props.buttonStyle,
              [":hover"]: {
                backgroundColor: $theme.colors.backgroundTertiary,
              },
            },
          },
        }}
      >
        <Column $style={{ alignItems: "start" }} $gap={$theme.sizing.scale100}>
          {props.label && (
            <div
              className={css({
                ...$theme.typography.LabelXSmall,
                color: $theme.colors.contentStateDisabled,
              })}
            >
              {props.label}
            </div>
          )}
          <div>{selected?.label}</div>
        </Column>
      </Button>
    </StatefulPopover>
  );
};

export const EnvironmentSelector = (props: {
  options: { id: EnvironmentSlug; label: string; frameworkVersion?: string }[];
  value: EnvironmentSlug;
  onChange: (item: { id: EnvironmentSlug }) => void;
  disabled?: boolean;
  buttonStyle?: StyleObject;
}) => {
  const [_css, $theme] = useStyletron();

  let filteredOptions = props.options.filter((option) => option.id.toLowerCase() !== "production");

  const [searchQuery, setSearchQuery] = useState("");

  if (searchQuery.trim().length > 0) {
    filteredOptions = filter(filteredOptions, searchQuery, { key: "label" });
  }

  return (
    <StatefulPopover
      placement={"topRight"}
      focusLock
      autoFocus
      overrides={{
        Inner: {
          style: {
            borderRadius: $theme.borders.radius300,
          },
        },
        Body: {
          style: {
            borderRadius: $theme.borders.radius300,
            marginLeft: $theme.sizing.scale300, // hacky way to fix the popover position
          },
        },
      }}
      content={() => (
        <Card $style={{ width: "328px" }} tabIndex={-1} data-testid="docs-app-list">
          <CardSection
            $style={{
              gap: $theme.sizing.scale0,
              ...paddingUtil("8px", "8px", 0, "8px"),
            }}
          >
            <Input
              type="search"
              autoFocus
              startEnhancer={<SearchIcon />}
              placeholder="search"
              value={searchQuery}
              onChange={(event) => setSearchQuery(event.currentTarget.value)}
              disabled={false}
            />
          </CardSection>
          <CardSection
            $padding="small"
            $style={{
              gap: $theme.sizing.scale0,
            }}
          >
            <Row
              $align="space-between"
              $style={{
                alignItems: "center",
                padding: $theme.sizing.scale300,
                borderRadius: $theme.borders.radius300,
                [":hover"]: {
                  cursor: "pointer",
                  backgroundColor: $theme.colors.primary50,
                },
              }}
              onClick={() => {
                props.onChange({ id: ProductionEnvironmentSlug });
              }}
            >
              <EnvironmentNamePill environment={ProductionEnvironmentName} />
              {props.value === "production" && <CheckmarkIcon />}
            </Row>
            <Divider />
            <Row>
              <ParagraphSmall>Development environments</ParagraphSmall>
            </Row>
            {filteredOptions.map((option) => {
              return (
                <Row
                  key={option.id}
                  $align="space-between"
                  $style={{
                    padding: $theme.sizing.scale300,
                    alignItems: "center",
                    borderRadius: $theme.borders.radius300,
                    [":hover"]: {
                      cursor: "pointer",
                      backgroundColor: $theme.colors.primary50,
                    },
                  }}
                  onClick={() => {
                    props.onChange({ id: option.id });
                  }}
                >
                  <EnvironmentNamePill environment={option.id} />
                  {option.id === props.value && <CheckmarkIcon />}
                </Row>
              );
            })}
          </CardSection>
        </Card>
      )}
    >
      <Row
        $gap={$theme.sizing.scale200}
        $style={{
          borderRadius: $theme.borders.radius200,
          margin: $theme.sizing.scale200,
          backgroundColor: $theme.colors.primary50,
          padding: $theme.sizing.scale100,
          [":hover"]: {
            cursor: "pointer",
            backgroundColor: $theme.colors.primary100,
          },
        }}
      >
        <Column
          $align="start"
          $gap={$theme.sizing.scale200}
          $style={{
            width: "100%",
          }}
        >
          <ParagraphXSmall
            $style={{
              color: $theme.colors.primary400,
            }}
          >
            Environment
          </ParagraphXSmall>
          <div>
            <EnvironmentNamePill environment={props.value} />
          </div>
        </Column>

        <ChevronDown size={24} />
      </Row>
    </StatefulPopover>
  );
};
