import { StyledSvg } from "baseui/icon";
import React from "react";

export const ActionsDocIcon = (props: React.ComponentProps<typeof StyledSvg>) => {
  return (
    <StyledSvg viewBox="0 0" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.56672 1.06665C9.47098 1.06676 9.37703 1.09264 9.29473 1.14158C9.21244 1.19051 9.14483 1.2607 9.09902 1.34478L4.85943 8.23123V8.23228C4.79893 8.321 4.76661 8.42592 4.76672 8.53332C4.76672 8.67477 4.82291 8.81042 4.92293 8.91044C5.02295 9.01046 5.15861 9.06665 5.30006 9.06665H8.23339L7.44381 14.2937C7.43679 14.3287 7.4333 14.3643 7.43339 14.4C7.43339 14.5414 7.48958 14.6771 7.5896 14.7771C7.68962 14.8771 7.82527 14.9333 7.96672 14.9333C8.06364 14.9332 8.15867 14.9066 8.24163 14.8565C8.32458 14.8064 8.39232 14.7346 8.43756 14.6489H8.4386L12.6636 7.78123L12.6584 7.77186C12.7215 7.68424 12.7667 7.58281 12.7667 7.46665C12.7667 7.17225 12.5278 6.93332 12.2334 6.93332H9.30006L10.0761 1.75728C10.0919 1.70634 10.1 1.65332 10.1001 1.59998C10.1001 1.45853 10.0439 1.32288 9.94385 1.22286C9.84383 1.12284 9.70817 1.06665 9.56672 1.06665Z"
        fill="#545454"
      />
    </StyledSvg>
  );
};
